import React, { forwardRef, useState } from 'react'

import { Icon } from '../../Icon'
import { Loader } from '../Loader'
import { IButton, IVariant } from './types'
import {
  Cta,
  DestructiveCta,
  DestructivePrimary,
  Ghost,
  GhostInverted,
  Primary,
  Secondary,
} from './variants'

const setVariant = (variant: IButton['variant']) => {
  if (variant === 'destructive') {
    console.warn(
      'Variant destructive will be deprecated, please use variant destructiveCta',
    )
  }

  switch (variant) {
    case 'primary':
      return Primary
    case 'secondary':
      return Secondary
    case 'cta':
      return Cta
    case 'ghost':
      return Ghost
    case 'ghostInverted':
      return GhostInverted
    case 'destructive':
      return DestructiveCta
    case 'destructiveCta':
      return DestructiveCta
    case 'destructivePrimary':
      return DestructivePrimary
    default:
      return Secondary
  }
}

const iconValidate = (
  iconBefore?: IButton['iconBefore'],
  iconAfter?: IButton['iconAfter'],
  size?: IButton['size'],
) => {
  if (!size) {
    return false
  }

  switch (size) {
    case 's':
      if (
        (typeof iconBefore === 'string' && iconBefore.includes('20-')) ||
        (typeof iconAfter === 'string' && iconAfter.includes('20-'))
      ) {
        return 'You need to use icon size 16 with small Button'
      } else {
        return false
      }
    case 'l':
      if (
        (typeof iconBefore === 'string' && iconBefore.includes('16-')) ||
        (typeof iconAfter === 'string' && iconAfter.includes('16-'))
      ) {
        return 'You need to use icon size 20 with large Button'
      } else {
        return false
      }
    default:
      return Secondary
  }
}

export const Button = forwardRef(
  (props: IButton, ref: React.Ref<HTMLDivElement>) => {
    const {
      variant = 'secondary',
      iconBefore,
      iconAfter,
      children,
      as = 'button',
      isDisabled,
      size = 'l',
      isLoading,
      onClick,
      ...rest
    } = props

    const buttonProps = {
      ref,
      iconBefore,
      iconAfter,
      size,
      isLoading,
      ...rest,
    }

    const iconInvalid = iconValidate(iconBefore, iconAfter, size)

    if (iconInvalid) {
      throw Error(iconInvalid)
    }
    const StyledButton = setVariant(variant)
    const [isMouseDown, setIsMouseDown] = useState(false)

    return (
      <StyledButton
        fontSize="m"
        lineHeight="m"
        fontWeight="medium"
        py={size === 'l' ? 'xxs' : 'xxxs'}
        px={size === 'l' ? 's' : 'xs'}
        forwardedAs={as ? as : 'button'}
        isDisabled={isDisabled}
        disabled={isDisabled}
        isMouseDown={isMouseDown as IVariant['isMouseDown']}
        onMouseDown={() => setIsMouseDown(true)}
        onBlur={() => setIsMouseDown(false)}
        aria-label={typeof children === 'string' ? children : undefined}
        onClick={isLoading ? undefined : onClick}
        {...buttonProps}
      >
        {iconBefore && <Icon name={iconBefore} mr="xxs" />}
        {children}
        {iconAfter && <Icon name={iconAfter} ml="xxs" />}
        {isLoading && <Loader variant={variant} />}
      </StyledButton>
    )
  },
)
