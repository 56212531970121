import { ProductType, Targeting } from '@ads/front-core/api'
import { POLAND_NAME } from '@ads/front-core/constants/countries'
import { TDictionary } from '@ads/front-core/interfaces'
import { isPublisherCampaign } from './campaign'

export const POLAND_OPTION_NAME = 'Poland'

export const hasTargetingElement = (elem): boolean =>
  elem?.included?.length > 0 || elem?.excluded?.length > 0 || !!elem?.attributes

const withIabCategories = ({
  iabCategories,
  formatForBackend,
  productType,
}: {
  iabCategories: Targeting['iabCategories']
  formatForBackend: boolean
  productType: ProductType
}): boolean => {
  if (isPublisherCampaign(productType)) {
    return false
  }
  if (!formatForBackend || iabCategories?.attributes?.excludeSensitive) {
    return true
  }
  return (
    iabCategories?.included?.length > 0 || iabCategories?.excluded?.length > 0
  )
}

export const targetingIncludeOrExclude = elem => ({
  ...(elem?.included && elem?.excluded
    ? (elem.included || []).length > 0
      ? {
          included: elem.included,
        }
      : {
          excluded: elem.excluded || [],
        }
    : elem?.excluded
    ? {
        excluded: elem.excluded || [],
      }
    : {
        included: elem?.included || [],
      }),
})

const included = <T extends keyof Targeting>(targeting: Targeting, key: T) =>
  ({
    included: (targeting || {})[key]?.included || [],
  } as Targeting[T])

export const formatTargeting = ({
  targeting,
  polandId,
  productType,
  formatForBackend,
}: {
  targeting: Targeting
  polandId?: number
  productType?: ProductType
  formatForBackend?: boolean
}): Targeting => ({
  ...(!polandId ||
  !targeting?.countries?.included?.length ||
  targeting?.countries?.included?.some(c => c === polandId)
    ? {
        gender: included(targeting, 'gender'),
        age: included(targeting, 'age'),
        regions: targetingIncludeOrExclude(targeting?.regions),
        ...(hasTargetingElement(targeting?.segments)
          ? {
              segments: {
                included: targeting?.segments?.included || [],
                excluded: targeting?.segments?.excluded || [],
              },
            }
          : {}),
        ...(hasTargetingElement(targeting?.topic)
          ? {
              topic: {
                included: targeting?.topic?.included || [],
                excluded: targeting?.topic?.excluded || [],
              },
            }
          : {}),
      }
    : {
        gender: { included: [] },
        age: { included: [] },
        regions: { included: [] },
      }),
  device: included(targeting, 'device'),
  cities: targetingIncludeOrExclude(targeting?.cities),
  countries: targetingIncludeOrExclude(targeting?.countries),
  ...(isPublisherCampaign(productType)
    ? {
        rekids: included(targeting, 'rekids'),
      }
    : {
        domain: targetingIncludeOrExclude(targeting?.domain),
      }),
  ...(withIabCategories({
    iabCategories: targeting?.iabCategories,
    formatForBackend,
    productType,
  }) && hasTargetingElement(targeting?.iabCategories)
    ? {
        iabCategories: {
          included: targeting?.iabCategories?.included || [],
          excluded: targeting?.iabCategories?.excluded || [],
          attributes: {
            excludeSensitive:
              targeting?.iabCategories?.attributes?.excludeSensitive || false,
            includeDomainCategories:
              targeting?.iabCategories?.attributes?.includeDomainCategories ||
              false,
            includeArticleCategories:
              targeting?.iabCategories?.attributes?.includeArticleCategories ||
              false,
          },
        },
      }
    : {}),
  ...(hasTargetingElement(targeting?.retargetingSegments)
    ? {
        retargetingSegments: {
          included: targeting?.retargetingSegments?.included || [],
          excluded: targeting?.retargetingSegments?.excluded || [],
        },
      }
    : {}),
})

export const withPolandTargeting = (
  targeting: Targeting,
  dictionary: TDictionary[],
): boolean => {
  const polandId = dictionary?.find(d => d.value === POLAND_NAME)?.id

  return (
    targeting?.countries?.included?.some(c => c === polandId) ||
    targeting?.countries?.excluded?.some(c => c === polandId)
  )
}
