import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const setFormDraft = createAction<any>('draft/setFormDraft')

export const setFormDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setFormDraft>,
): void => {
  state.form = cloneDeep(action.payload)
}
